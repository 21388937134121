
<template lang="pug">

</template>

<script>

export default {
	name: 'Home',
	components: {
	}
}
</script>
